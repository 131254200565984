import "./App.css"; 
import Routing from "./Routing";

function App() {
  return (
    <Routing/>
  );
}

export default App;
