import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

 const About = () => {
  return (
    <>
      {/* header test */}
      <header id="header" class="d-flex align-items-center header-transparent">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo">

            <Link to="/">
              <img src="img/logo.svg" alt="" class="img-fluid" />{" "}
            </Link>
          </div>

          {/* <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://admin.nlgfantasy.com/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav> */}

          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec">
        <div class="heading">
          <h1>About Us</h1>
        </div>
      </section>

      <main id="main">
        <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>About Us</li>
              </ol>
            </div>
          </div>
        </section>
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page">
          <div class="container">
            <div class="content">
              <div>
                <h3 className="mb-4" >Welcome to "NLG Fantasy" </h3>
                <p class="text-justify"> 
                  "Sports" and Fantasy is our identity. We are not just a website that provides true cricket fans a place to live out their cricket fantasies. Our goal is to enhance the user's ability to play fantasy games in a 100% secure environment.
                </p>  
                <p>
                NLG Fantasy is India's fastest growing fantasy sports website designed for sports fans, especially cricket and cricket fans. Indian football fans! We believe that winning cash prizes is an important reason to play fantasy sports in India, but it is not the only reason. Fantasizing is fun because it doubles the excitement of watching the game.
                </p>
                <p>
                Established in 2024, NLG Fantasy's mission is to provide entertainment and become India's most trusted fantasy sports platform. We are just at the beginning of our amazing journey.
                </p>
                <p>
                NLG Fantasy continuously improves the quality of its offerings and the range of services, which has resulted in NLG Fantasy becoming a well-known brand in a short period of time.
                </p>
                <p>
                Join NLG Fantasy and enjoy online cricket at your fantasy destination.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />

      {/* <div id="preloader"></div> */}
    </>
  );
};

export default About
