import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

 const FantasyPointSystem = () => {
  return (
    <>
      {/* header */}
      <header id="header" class="d-flex align-items-center header-transparent">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo">

            <Link to="/">
              <img src="img/logo.svg" alt="" class="img-fluid" />{" "}
            </Link>
          </div>

          {/* <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://admin.nlgfantasy.com/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav> */}

          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec">
        <div class="heading">
          <h1>Fantasy Points System  </h1>
        </div>
      </section>

      <main id="main">
        <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Fantasy Points System  </li>
              </ol>
            </div>
          </div>
        </section>
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page">
	<div class="container">
		<div class="content">
			<div class="row justify-content-center">
				<div class="col-lg-9">
					<div class="points_row">
						<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
							<li class="nav-item" role="presentation">
								<button class="nav-link active" id="pills-T20-tab" data-bs-toggle="pill" data-bs-target="#pills-T20" type="button" role="tab" aria-controls="pills-T20" aria-selected="true">T20</button>
							</li>
							<li class="nav-item" role="presentation">
								<button class="nav-link" id="pills-ODI-tab" data-bs-toggle="pill" data-bs-target="#pills-ODI" type="button" role="tab" aria-controls="pills-ODI" aria-selected="false" tabindex="-1">ODI</button>
							</li>
							<li class="nav-item" role="presentation">
								<button class="nav-link" id="pills-Test-tab" data-bs-toggle="pill" data-bs-target="#pills-Test" type="button" role="tab" aria-controls="pills-Test" aria-selected="false" tabindex="-1">Test</button>
							</li>
							<li class="nav-item" role="presentation">
								<button class="nav-link" id="pills-T10-tab" data-bs-toggle="pill" data-bs-target="#pills-T10" type="button" role="tab" aria-controls="pills-T10" aria-selected="false" tabindex="-1">T10</button>
							</li>
						</ul>
						<div class="tab-content pt-3" id="pills-tabContent">
							<div class="tab-pane  show active">
								<div class="accordion  custom_accordians " id="accordionExample">
									<div class="accordion-item">
										<h2 class="accordion-header" id="bp_1"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsebp_1" aria-expanded="false" aria-controls="collapsebp_1">Batting Points</button></h2>
										<div id="collapsebp_1" class="accordion-collapse collapse" aria-labelledby="bp_1" data-bs-parent="#accordionExample">
											<div class="card-body pointBody">
												<table class="table pointTable">
													<thead>
														<tr>
															<th scope="col">Type</th>
															<th scope="col">Point</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>Run</td>
															<td>1</td>
														</tr>
														<tr>
															<td>Boundary Bonus</td>
															<td>1</td>
														</tr>
														<tr>
															<td>Six Bonus</td>
															<td>2</td>
														</tr>
														<tr>
															<td>30 Runs Bonus</td>
															<td>4</td>
														</tr>
														<tr>
															<td>50 Runs Bonus</td>
															<td>8</td>
														</tr>
														<tr>
															<td>Century Bonus</td>
															<td>16</td>
														</tr>
														<tr>
															<td>Dismissal for a duck
																<br/> <small class="fw-12">Batsman, Wicket-Keeper &amp; All-Rounder</small> </td>
															<td>-2</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="bowlp_1"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsebowlp_1" aria-expanded="false" aria-controls="collapsebowlp_1">Bowling Points</button></h2>
										<div id="collapsebowlp_1" class="accordion-collapse collapse" aria-labelledby="bowlp_1" data-bs-parent="#accordionExample">
											<div class="card-body pointBody">
												<table class="table pointTable">
													<thead>
														<tr>
															<th scope="col">Type</th>
															<th scope="col">Point</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>Wicket (Excluding run out)</td>
															<td>25</td>
														</tr>
														<tr>
															<td>4-wicket haul bonus</td>
															<td>8</td>
														</tr>
														<tr>
															<td>5-wicket haul bonus</td>
															<td>16</td>
														</tr>
														<tr>
															<td>Maiden over</td>
															<td>12</td>
														</tr>
														<tr>
															<td>3-wicket haul bonus</td>
															<td>4</td>
														</tr>
														<tr>
															<td>Bonus (LBW/Bowled)</td>
															<td>8</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="fp_1"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefp_1" aria-expanded="false" aria-controls="collapsefp_1">Fielding Points</button></h2>
										<div id="collapsefp_1" class="accordion-collapse collapse" aria-labelledby="fp_1" data-bs-parent="#accordionExample">
											<div class="card-body pointBody">
												<table class="table pointTable">
													<thead>
														<tr>
															<th scope="col">Type</th>
															<th scope="col">Point</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>Catch</td>
															<td>8</td>
														</tr>
														<tr>
															<td>Stumping</td>
															<td>12</td>
														</tr>
														<tr>
															<td>Runout (Direct)</td>
															<td>12</td>
														</tr>
														<tr>
															<td>Runout (Not a direct hit)</td>
															<td>6</td>
														</tr>
														<tr>
															<td>3 catch haul bonus</td>
															<td>4</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="sr_1"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesr_1" aria-expanded="false" aria-controls="collapsesr_1">Other</button></h2>
										<div id="collapsesr_1" class="accordion-collapse collapse" aria-labelledby="sr_1" data-bs-parent="#accordionExample">
											<div class="card-body pointBody">
												<table class="table pointTable">
													<thead>
														<tr>
															<th scope="col">Type</th>
															<th scope="col">Point</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>Captain</td>
															<td>2</td>
														</tr>
														<tr>
															<td>Vice-Captain</td>
															<td>1.5</td>
														</tr>
														<tr>
															<td>In announced lineups</td>
															<td>4</td>
														</tr>
														<tr>
															<td>Playing substitue
																<br/> <small> (Concussion, COVID-19, X-Factor, or Impact Player)</small></td>
															<td>4</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="er_1"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseer_1" aria-expanded="false" aria-controls="collapseer_1">Economy Rate<small>Only applicable on Bowlers &amp; All Rounders</small></button></h2>
										<div id="collapseer_1" class="accordion-collapse collapse" aria-labelledby="er_1" data-bs-parent="#accordionExample">
											<div class="card-body pointBody">
												<table class="table pointTable">
													<thead>
														<tr>
															<th scope="col">Type</th>
															<th scope="col">Point</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>Below 5 runs per over</td>
															<td>6</td>
														</tr>
														<tr>
															<td>Below 5-6.99 runs per over</td>
															<td>4</td>
														</tr>
														<tr>
															<td>Between 6-7 runs per over</td>
															<td>2</td>
														</tr>
														<tr>
															<td>Between 10-11 runs per over</td>
															<td>-2</td>
														</tr>
														<tr>
															<td>Between 11-12 runs per over</td>
															<td>-4</td>
														</tr>
														<tr>
															<td>Above 12 runs per over</td>
															<td>-6</td>
														</tr>
													</tbody>
												</table>
												<ul>
													<li>
														<p>The points for Economy Rate will be awarded to all the players but in case of deductions, the points for negative economy rate will be deducted only for all- rounders and bowlers.</p>
													</li>
													<li>
														<p>The economy rate in case of T20 is counted in 2nd over, in case of ODI in 5th over, not counted in case of Test Match &amp; from the first over in case of T10.</p>
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="strikerate_1"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsestr_1" aria-expanded="false" aria-controls="collapsestr_1">Strike Rate</button></h2>
										<div id="collapsestr_1" class="accordion-collapse collapse" aria-labelledby="strikerate_1" data-bs-parent="#accordionExample">
											<div class="card-body pointBody">
												<table class="table pointTable">
													<thead>
														<tr>
															<th scope="col">Type</th>
															<th scope="col">Point</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>Below 50 runs per 100 balls</td>
															<td>-10</td>
														</tr>
														<tr>
															<td>Between 50-59 runs per 100 balls</td>
															<td>-4</td>
														</tr>
														<tr>
															<td>Between 60-70 runs per 100 balls</td>
															<td>-5</td>
														</tr>
														<tr>
															<td>Between 130-150 runs per 100 balls</td>
															<td>2</td>
														</tr>
														<tr>
															<td>Between 150-170 runs per 100 balls</td>
															<td>10</td>
														</tr>
														<tr>
															<td>Above 170 runs per 100 balls</td>
															<td>6</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
      </main>
      <Footer />

      {/* <div id="preloader"></div> */}
    </>
  );
};

export default FantasyPointSystem
