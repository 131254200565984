import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const Contact = () => {
  return (
    <>
      <header id="header" class="d-flex align-items-center header-transparent ">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo">
            <Link to="/">
              <img src="img/logo.svg" alt="" class="img-fluid" />
            </Link>
          </div>
        </div>
      </header>
      <section class="inner_hero_sec">
        <div class="heading">
          <h1>Contact Us</h1>
        </div>
      </section>
      <main id="main">
        <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Contact Us</li>
              </ol>
            </div>
          </div>
        </section>
        <section id="contact" class="contact">
          <div class="container">
            <div class="section-title" data-aos="fade-up">
              <h2>Contact</h2>
              <p>Contact Us</p>
            </div>
            <div class="row align-items-start">
              <div class="col-lg-12" data-aos="fade-right" data-aos-delay="100">
                <div class="info row align-items-start">
                  <div class="email  col-md-6 mt-md-0">
                    <i class="bi bi-envelope"></i>
                    <h4>Email: </h4>
                    <p> <a href="mailto:contact@nlgfantasy.com " > contact@nlgfantasy.com  </a> </p>
                  </div> 
                  {/* <div class="email  col-md-4  mt-md-0">
                    <i class="bi bi-phone"></i>
                    <h4>Call:</h4>  
                    <p> <a href="tel:1236549874" > +91 123-456-7890 </a> </p>  
                  </div> */}
                  <div class="phone col-md-6  mt-md-0">
                    <i class="bi bi-geo-alt"></i>
                    <h4>Location:</h4>
                    <p>Fl No. 101, 2nd Floor, Shivani Hill View, Anjali Gardens, Puppalaguda, Manikonda,Hyderabad - 500089</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <a href="#" class="back-to-top d-flex align-items-center justify-content-center">
        <i class="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
};
