import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const Faq = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <header id="header" class="d-flex align-items-center header-transparent ">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo">
            {/* <!-- <h1><a href="index.html"><span>Bootslander</span></a></h1> -->
        <!-- Uncomment below if you prefer to use an image logo --> */}
            <Link to="/">
              <img src="img/logo.svg" alt="" class="img-fluid" />
            </Link>
          </div>

          {/* <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://backend.www.nlgfantasy.com/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav> */}

          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec">
        <div class="heading">
          <h1>Faq   </h1>
        </div>
      </section>

      <main id="main">
        <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Faq  </li>
              </ol>
            </div>
          </div>
        </section>
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page faq section-bg">
          <div class="container">
            <div class="content">
              <div class="TermsCondtns">
                  <div class="TermsCondtnsBox">

                  <div class="faq-list">
                    <ul>
                      <li data-aos="fade-up">
                        <a
                          data-bs-toggle="collapse"
                          class="collapse"
                          data-bs-target="#faq-list-1"
                        >
                        What do you understand by& Fantasy cricket? 
                          <i class="bx bx-chevron-down icon-show"></i>
                          <i class="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-1"
                          class="collapse show"
                          data-bs-parent=".faq-list"
                        >
                          <p>
                          It is a virtual cricket game where you can select a team of 11 players based on your own preferences. Select your exclusive team based on the past performances, toss results, physical fitness, weaknesses, and inabilities, and make your team. You will become a fantasy league player if you have good logic, expert thinking skills, and extensive research. You can play fantasy cricket leagues or fantasy football leagues.	
                          </p>
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="100">
                        <a
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-list-2"
                          class="collapsed"
                        >
                          What is the popularity of Fantasy cricket in India?
                          <i class="bx bx-chevron-down icon-show"></i>
                          <i class="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-2"
                          class="collapse"
                          data-bs-parent=".faq-list"
                        >
                          <p>
                          Indians are completely fanatic about cricket. Cricket is in their soul. It's not only a game, rather its an emotion. The 2019 estimation of the fantasy game users in India showed a projection of around $5billion. Since it’s a mind game and gives Indians the opportunity of relieving their on-field glory, hence the majority of the young sports lovers prefer fantasy cricket in India
                          </p>
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="200">
                        <a
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-list-3"
                          class="collapsed"
                        >
                          How to play Fantasy sports and win real cash? 
                          <i class="bx bx-chevron-down icon-show"></i>
                          <i class="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-3"
                          class="collapse"
                          data-bs-parent=".faq-list"
                        >
                          <p>
                          It’s a very easy process. To set up your dream team follow these simple steps: 
                            <ul className="mt-3" >
                          <li className="p-0" >  -Choose a match from the dashboard that interests you. </li>
                          <li className="p-0">  -Select the good players and create a team of 11 players. </li>
                          <li className="p-0"> -Select a good captain and vice-captain. </li>
                          <li className="p-0"> -Enter the contest by choosing the offers</li>
                          <li className="p-0"> -Follow the live match and track your progress. </li>
                          <li className="p-0"> -As soon as the live match is over and the winning team is declared, you’ll get
                            to view the final ranking and winning amount on your dashboard. </li>
                            </ul>

                          </p>
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="300">
                        <a
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-list-4"
                          class="collapsed"
                        >
                          Is it safe to provide bank details on Fantasy sports apps?
                          <i class="bx bx-chevron-down icon-show"></i>
                          <i class="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-4"
                          class="collapse"
                          data-bs-parent=".faq-list"
                        >
                          <p>
                          Fantasy leagues played on a completely safe platform. You can trust the payment methods 100%. When you enter the game, the websites will ask for your PAN and bank details. you’ll be receiving a certain amount of joining bonus and referral bonuses as well, hence you need to provide the correct bank details on the portal. 
                          </p>
                          <p>
                            <small>
                            Note: Don’t save any card details including CVV and PAN details while making any kind of payment.
                            </small>
                          </p>
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="400">
                        <a
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-list-4a"
                          class="collapsed"
                        >
                          Are Fantasy sports legal in India?
                          <i class="bx bx-chevron-down icon-show"></i>
                          <i class="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-4a"
                          class="collapse"
                          data-bs-parent=".faq-list"
                        >
                          <p>
                          Online fantasy cricket in India is a safe, secure, and completely legal game. The playing procedure of fantasy sports doesn’t match that of the provisions laid under the Public Gambling Act 1867. Hence, it is a “mere game of skill” and not a gambling act. Hence it is completely legitimate. Article 19 (1) of the Constitution of India has declared the same to be legal and safe. It is 100% legal in India. Enjoy a hassle-free match.
                          </p>
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="400">
                        <a
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-list-5"
                          class="collapsed"
                        >
                          Are Fantasy sports banned in a few states of India?
                          <i class="bx bx-chevron-down icon-show"></i>
                          <i class="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-5"
                          class="collapse"
                          data-bs-parent=".faq-list"
                        >
                          <p>
                          Yes. Fantasy games are restricted in few states of India like Assam, Odisha, Sikkim, Nagaland, and Telangana. Although the Indian Constitution has declared daily fantasy games as legal, however, the state governments of different states of India sets out the rules for the daily fantasy games. Hence, any person trying to register in these apps from the above-mentioned states will not be permitted as per the state government prohibitions.
                          </p>
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="400">
                        <a
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-list-6"
                          class="collapsed"
                        >
                          Is the money earned from Fantasy sports taxable in India?
                          <i class="bx bx-chevron-down icon-show"></i>
                          <i class="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-6"
                          class="collapse"
                          data-bs-parent=".faq-list"
                        >
                          <p>
                          Yes. Under the provisions of Income Tax Act 1961, Tax deducted at source (TDS) is applied to the winning amount and you’ll receive the winning amount after the same has been deducted by the companies. If applicable you’ll also receive a legal TDS certificate from the company’s end for future references. 
                          </p>
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="400">
                        <a
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-list-8"
                          class="collapsed"
                        >
                          Can I play Fantasy sports from all mobile devices? 
                          <i class="bx bx-chevron-down icon-show"></i>
                          <i class="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-8"
                          class="collapse"
                          data-bs-parent=".faq-list"
                        >
                          <p>
                          Of course, you can. The beauty of online fantasy games is that you can play them anytime and at any place from any device – be it a smartphone, tablet, PC, or a laptop! All apps are designed to make them mobile user-friendly. The HTML versions of the apps are fully adapted for mobile phones. By making them compatible with all types of devices, we’re trying to make the gaming experience seamless, smooth, and enjoyable. 
                          </p>
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="400">
                        <a
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-list-9"
                          class="collapsed"
                        >
                          How do the referral bonuses work? 
                          <i class="bx bx-chevron-down icon-show"></i>
                          <i class="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-9"
                          class="collapse"
                          data-bs-parent=".faq-list"
                        >
                          <p>
                          You can earn cash in daily fantasy cricket with attractive referral bonuses as well. As soon as you download the apps, most fantasy sports sites will highlight the referral bonus part where you can refer new players and get rich with a flash of seconds! You get a percentage of commission for promoting the site. The bonus rates may vary from site to site.
                          </p>
                        </div>
                      </li> 



                      <li data-aos="fade-up" data-aos-delay="400">
                        <a
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-list-10"
                          class="collapsed"
                        >
                          What are the unique features of NLGFANTASY? 
                          <i class="bx bx-chevron-down icon-show"></i>
                          <i class="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-10"
                          class="collapse"
                          data-bs-parent=".faq-list"
                        >
                          <p>
                          For cricket-crazy people, NLGFANTASY is the simplest, thrilling, and realistic fantasy sports platform which will give you the opportunity of winning huge amounts of cash and let you sharpen your gaming skills.
                          </p>
                          <p>
                          Let’s jot down a few pointers: 
                          </p>
                          <ul className="mt-3" > 
                            <li className="p-0" >All device compatible gaming application.</li> 
                            <li className="p-0" >Attractive registration fees and referral bonuses. </li>
                            <li className="p-0" >Nail-biting cricket and football leagues. </li>
                            <li className="p-0" >Pro tips on how to play fantasy games24/7 prompt customer support system. </li>
                          </ul>


                        </div>
                      </li> 


                    </ul>
                  </div>
                  
                  </div>
                </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      <Footer />
      {/* <!-- End Footer --> */}

      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>
      {/* <div id="preloader"></div> */}
    </>
  );
};
