export const Footer = () => {
  return (
    <>
      <footer id="footer">
        <div class="footer-top">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <div class="footer-info">
                  <h3> NLG Fantasy</h3>
                  <p class="pb-3">
                    <em>
                    NLG Fantasy is an emerging online gaming platform in
                      India that brings various enthralling online games in
                      Android and iOS mobile platforms for sports & game lovers.
                      Join our fantasy gaming platform, play your favorite game,
                      and win exciting rewards and cash prizes.
                    </em>
                  </p>
                </div>
              </div>

              <div class="col-lg-2 col-md-6 footer-links">
                <h4>Quick Links</h4>
                <ul> 
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/about">About us</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/termsNConditions"> Terms and Conditions </a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/policy">Privacy policy</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/refund_policy"> Cancellation/Refund Policy </a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/legalities"> Legalities   </a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/responsible_gaming"> Responsible Gaming    </a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/faq"> Frequently Asked Questions   </a>
                  </li> 
                </ul>
              </div>

              <div class="col-lg-2 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/fairPlay">Fair Play</a>
                  </li>
                  {/* <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/fantasy_points_system"> Fantasy Point System </a>
                  </li> */}
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/howToPlay">How to play</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="/contact">Contact us</a>
                  </li>
                </ul>
              </div>

              <div class="col-lg-4 col-md-6 footer-newsletter">
                <h4>Payment Partners</h4>

                <div class="payments-partner mb-4">
                  <img src="img/payment_partner.png" alt="" class="img-fluid" />{" "}
                </div>

                {/* <p><strong>Phone: </strong> +91 123-456-7890</p> */}

                <p> 

                  <strong>Address: </strong> Fl No. 101, 2nd Floor, Shivani Hill View, Anjali Gardens, Puppalaguda, Manikonda,Hyderabad - 500089
                </p> 

                <p>
                  <strong>Email: </strong> contact@nlgfantasy.com
                </p>

                <div class="social-links mt-3"> 
                  <a target="_blank" href="https://www.instagram.com/NLG Fantasy_/?igshid=MzRlODBiNWFlZA%3D%3D" class="instagram">
                    <i class="bx bxl-instagram"></i>
                  </a>
                  <a target="_blank" href="https://www.youtube.com/@NLG Fantasy" class="youtube">
                    <i class="bx bxl-youtube"></i>
                  </a>
                  <a target="_blank" href="https://t.me/NLG Fantasyfantasycricketprediction" class="linkedin">
                    <i class="bx bxl-telegram"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="copyright">
          Copyright © 2024 SRINIDHI SMART BUCKS LLP. 
          </div>
        </div>
      </footer>
    </>
  );
};
