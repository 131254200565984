import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const TermsNConditions = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <header id="header" class="d-flex align-items-center header-transparent ">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo">
            {/* <!-- <h1><a href="index.html"><span>Bootslander</span></a></h1> -->
        <!-- Uncomment below if you prefer to use an image logo --> */}
            <Link to="/">
              <img src="img/logo.svg" alt="" class="img-fluid" />
            </Link>
          </div>

          {/* <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://admin.nlgfantasy.com/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav> */}

          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec">
        <div class="heading">
          <h1>Terms and Conditions</h1>
        </div>
      </section>

      <main id="main">
        <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Terms and Conditions</li>
              </ol>
            </div>
          </div>
        </section>
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page">
          <div class="container">
            <div class="content">
              <div class="TermsCondtns">
                <div class="TermsCondtnsBox">
                  {/* <h5>Last Updated: 22 June, 2023</h5> */}
                  <p>
                  We are unable to offer our services to players in the Indian states of Andhra Pradesh, Assam, Nagaland, Sikkim and Telangana due to the applicable laws. State or Central Governments of India may impose penalties on such players.
                   </p>
                   <p>
                   www.nlgfantasy.com does not accept registration requests from residents of Andhra Pradesh, Assam, Nagaland, Sikkim and Telangana. The player in Andhra Pradesh, Assam, Nagaland, Sikkim and Telangana, Tamil Nadu and Andhra Pradesh will be solely responsible if they create an account on the site with false information (incorrect state, IP address, etc.). A player(s) account can be terminated by the company without prior notice and the company may also confiscate the deposit and/or winnings of such player(s).
                   </p>
                   <p>
                   We will refer hereinafter to all of the products/services offered to you and/or your players by Ability and/or its affiliates, subsidiaries, associates and partners, referred to as 'We', 'Us' or 'Our' through the skill gaming website www.nlgfantasy.com, hereafter referred to as 'Website'. The use of the Website by You is governed by these Terms of Service, which will hereafter be referred to as 'Term'.
                   </p>
                   <p>
                   IT IS MANDATORY AND BINDING ON 'YOU' THAT THESE TERMS SHOULD BE READ BY 'YOU' IN THEIR ENTIRETY PRIOR TO YOUR USE OF THE 'SERVICES'.
                   </p>
                   <p>
                   It should be noted that any Term/s herein found to be unlawful, invalid, void, voidable, or unenforceable for any reason by any judicial or quasi-judicial body in India, in no way would affect the validity and enforceability of the remaining Terms. Such Terms will be replaced with another valid and enforceable. Any unlawful, invalid, void, voidable, or unenforceable Term will be replaced with another Term which is valid and enforceable and is close to the content and original intent of the one that has been adjudged as invalid.
                   </p>


                   <p> By Your registration on the Website, it is assumed by ‘Us’ that you have understood all the terms herein and have accepted the same. Printing of all transaction records, rules of play, cancellation policies and pay-out policies is what we recommend that you do. </p>
                   <p>  Delay on our part in taking action or exercising our rights or taking remedial approach with respect to breach of any Terms by You does not waive our rights to act on a later date, nor does it amount to any lapse and neither does that result invalidity of those Terms for any breach of similar kind in the future.</p>
                   <p>  The following Terms or any future alterations or modifications to these Terms are not acceptable by you; we recommend that ‘You’ may discontinue the use of website and any Services offered by it.</p>
                   
                   
                   
                   
                    





                  <ol class="termsContent ftFx13 terms_condition_list">
                    <li>
                      <h3 id="NLG Fantasy"> Usage of NLG Fantasy</h3>
                      <div class="t_c_content">
                        <div class="pdLt20">
                          <p> For information and database recovery services provided by NLG Fantasy website, players participating in contests and games (including fantasy games) and gaming services conducted on NLG Fantasy ("Contests"), as well as any other NLG Fantasy rules, regulations and terms of use referring to or offered by NLG Fantasy regarding any of NLG Fantasy services, are bound by these Terms and Conditions. </p>
                          <p> NLG Fantasy has liberty to modify Terms and Conditions, regulations and terms of use referred to or offered by NLG Fantasy in relation to any NLG Fantasy Services, at any time, by posting same on NLG Fantasy. Use of NLG Fantasy has Player's acceptance of such Terms and Conditions, rules and terms of use referred to or offered by NLG Fantasy in relation to any NLG Fantasy Services, as may be amended from time to time. NLG Fantasy may also notify Player of any change or modification in these Terms and Conditions, rules, regulations and terms of use referred to or offered by NLG Fantasy, by way of sending an email to Player's registered email address or posting notifications in Player accounts. Player might then exercise options offered in such an email or notification to indicate non-acceptance of modified Terms and Conditions, rules, regulations and terms of use referred to or offered by NLG Fantasy. If such options are not exercised by Player within time frame prescribed in email or notification, Player will be deemed to have accepted modified Terms and Conditions, rules, regulations and terms of use referred to or offered by NLG Fantasy.</p>
                          <p> Certain NLG Fantasy Services being offered on NLG Fantasy may be subject to additional rules and regulations set down in that respect. To the extent that these Terms and Conditions are inconsistent with additional conditions set down, additional conditions shall prevail.</p>

                          <p>   NLG Fantasy may, at its sole and absolute discretion: </p>
                          <p>   Restrict, suspend, or terminate any Player's access to all or any part of NLG Fantasy or NLG Fantasy Services; </p>
                          <p>  Change, suspend, or discontinue all or any part of NLG Fantasy Services; </p>
                          <p>   Reject, move, or remove any material that may be submitted by a Player; </p>


                          <p>  Move or remove any content that is available on NLG Fantasy; </p>
                          <p> Deactivate or delete a Player's account and all related information and files on account; </p>
                          <p> stablish general practices and limits concerning use of NLG Fantasy; </p>


                          <p> Revise or make additions to the roster of players available for opting in a Contest on account of revisions to the roster of players involved in the relevant Sports Event; </p>
                          <p>  Assign its rights and liabilities to all Player accounts hereunder to any entity (post intimation of such assignment shall be sent to all Players to their registered email ids) </p>


                          <p> If any Player breaches, or NLG Fantasy reasonably believes that such Player has breached these Terms and Conditions, or has illegally or inappropriately used NLG Fantasy or the NLG Fantasy Services, NLG Fantasy may, at its sole and absolute discretion, and without any notice to the Player, restrict, suspend or terminate such Player's access to all or any part of NLG Fantasy or the NLG Fantasy Services, deactivate or delete the Player's account and all related information on the account, delete any content posted by the Player on NLG Fantasy and further, take technical and legal steps as it deems necessary. </p>
                          <p>  If NLG Fantasy charges its Players a platform fee in respect of any NLG Fantasy Services, NLG Fantasy shall, without delay, repay such platform fee in the event of suspension or removal or the Player's account or NLG Fantasy Services on account of any negligence or deficiency on the part of NLG Fantasy, but not if such suspension or removal is effected due to: </p>

                          <p> any breach or inadequate performance by the Player of any of these Terms and Conditions; or
                          all situations beyond the reasonable control of NLG Fantasy. </p>

                          <p> Players consent to receiving communications such as announcements, administrative messages and advertisements from NLG Fantasy or any of its partners, licensors or associates. </p>

                        
                        </div>
                      </div>
                    </li>



                    <li>
                      <h3 id="NLG Fantasy">     Intellectual Property    </h3>
                      <div class="t_c_content">
                        <div class="pdLt20">

                        <p> NLG Fantasy includes a combination of content created by NLG Fantasy, its partners, licensors, associates and/or Players. The intellectual property rights ("Intellectual Property Rights") in all software underlying NLG Fantasy and the NLG Fantasy Services and material published on NLG Fantasy, including (but not limited to) games, Contests, advertisements, software, photographs, written content, images, graphics, marks, illustrations, audio, logos or video clippings and Flash animation, is owned by NLG Fantasy, its partners, licensors and/or associates. Players may not transmit, publish, participate in the transfer or sale of, reproduce, create derivative works of, distribute, publicly perform, publicly display, or in any way exploit any of the materials or content on NLG Fantasy either in whole or in part without express written license from NLG Fantasy. </p>
                        <p> Players may request permission to use any NLG Fantasy content by writing in to NLG Fantasy Support. </p>
                        <p> Players confirm and undertake to not display or use of the names, marks, logos, trademarks, labels, copyrights or intellectual and proprietary rights of any third party on NLG Fantasy. Players agree to cover and hold harmless NLG Fantasy, its directors, affiliates, employees and assigns against all costs, loss and harm including towards litigation costs and counsel fees, damages, in respect of any third party claims that may be initiated including for infringement of Intellectual Property Rights arising out of such display or use of the names, marks, logos, trademarks, labels, copyrights or intellectual rights on NLG Fantasy, by such Player or through the Player's commissions or omissions. </p>
                        <p> Players hereby grant to NLG Fantasy and its affiliates, partners, licensors and associate a worldwide, irrevocable, royalty-free, non-exclusive, sub-licensable license to use, reproduce, create derivative works of, distribute, publicly perform, publicly display, transfer, transmit, and/or publish Players' Content for any of the following purposes: </p>

                        <p> displaying Players' Content on NLG Fantasy </p>
                        <p> distributing Players' Content, either by machine or via other media, to other Players looking for downloading or else get it, and/or
                         storing Players' Content in a remote database accessible by end players, for a charge. </p>

                        <p> This license shall apply to the distribution and the storage of Players' Content in any form, medium, or technology. </p>
                        <p> All names, marks, logos, trademarks, labels, proprietary rights and copyrights or intellectual rights on NLG Fantasy belonging to any person (including Player), entity or third party are recognized as proprietary to the respective owners and any claims, controversy or issues against these names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights must be directly addressed to the respective parties under notice to NLG Fantasy. </p>
 

                        </div>
                      </div>
                    </li>


                    

                    <li>
                      <h3 id="NLG Fantasy">     Third Party Sites, Services and Products </h3>
                      <div class="t_c_content">
                        <div class="pdLt20">  
                          
                        <p> NLG Fantasy may contain links to other Internet sites owned and operated by third parties. Players' use of each of those sites is subject to the conditions, if any, posted by the sites. NLG Fantasy does not exercise control over any Internet sites apart from NLG Fantasy, and cannot be held responsible for any content residing in any third party Internet site. NLG Fantasy's inclusion of third-party content or links to third-party Internet sites is not an endorsement by NLG Fantasy of such third-party Internet site. </p>
                        <p>   Players' correspondence, transactions or related activities with third parties, including payment providers and verification service providers, are solely between the Player and that third party. Players' correspondence, transactions and usage of the services of such third party shall be subject to the terms and conditions, policies and other service terms adopted/implemented by such third party, and the Player shall be solely responsible for reviewing the same prior to transacting or availing of the services of such third party. Player agrees that NLG Fantasy will not be responsible or liable for any loss or damage of any sort incurred as a result of any such transactions with third parties. Any questions, complaints, or claims related to any third party product or service should be directed to the appropriate vendor. </p>
                        <p>   NLG Fantasy contains content that is created by NLG Fantasy as well as content offered by third parties. NLG Fantasy does not guarantee the accuracy, integrity, quality of the content offered by third parties and such content may not relied upon by the Players in utilizing the NLG Fantasy Services offered on NLG Fantasy including while participating in any of the contests hosted on NLG Fantasy. </p>
 
                        </div>
                      </div>
                    </li>

 

                    <li>
                      <h3 id="NLG Fantasy">     NLG Fantasy Players Conduct </h3>
                      <div class="t_c_content">
                        <div class="pdLt20">
                          
                        <p>   Players agree to abide by these Terms and Conditions and all other rules, regulations and terms of use of the Website. If Player does not abide by these Terms and Conditions and all other rules, regulations and terms of use, NLG Fantasy may, at its sole and absolute discretion, take necessary remedial action, including but not limited to: </p>

                        <p>   restricting, suspending, or terminating any Player's access to all or any part of NLG Fantasy Services; </p>
                        <p>   deactivating or deleting a Player's account and all related information and files on the account. Any amount remaining unused in the Player's Game account or Winnings Account on the date of deactivation or deletion shall be transferred to the Player's bank account on record with NLG Fantasy subject to a processing fee (if any) applicable on such transfers as set out herein; or
                            refraining from awarding any prize to such Player. </p>

                        <p>   Players agree to offer true, accurate, current and complete information at the time of registration and at all other times (as needed by NLG Fantasy). Players further agree to update and keep updated their registration information. </p>
                        <p>   A Player shall not register or operate more than one Player account with NLG Fantasy. </p>
                        <p>   Players agree to ensure that they can receive all communication from NLG Fantasy by marking e-mails from NLG Fantasy as part of their "safe senders" list. NLG Fantasy shall not be held liable if any e-mail remains unread by a Player as a result of such e-mail getting delivered to the Player's junk or spam folder. </p>
                        <p>   Any password issued by NLG Fantasy to a Player may not be revealed to anyone else. Players may not use anyone else's password. Players are responsible for maintaining the confidentiality of their accounts and passwords. Players agree to immediately notify NLG Fantasy of any unauthorized use of their passwords or accounts or any other breach of security. </p>
                        <p>   Players agree to exit/log-out of their accounts at the end of each session. NLG Fantasy shall not be responsible for any loss or damage that may result if the Player fails to comply with these needs. </p>
                        <p>   Players agree not to use cheats, exploits, automation, software, bots, hacks or any unauthorised third party software designed to modify or interfere with NLG Fantasy Services and/or NLG Fantasy experience or assist in such activity. </p>
                        <p>   Players agree not to copy, modify, rent, lease, loan, sell, assign, distribute, reverse engineer, grant a security interest in, or otherwise transfer any right to the technology or software underlying NLG Fantasy or NLG Fantasy Services. </p>
                        <p>   Players agree that without NLG Fantasy's express written consent, they shall not modify or cause to be modified any files or software that is part of NLG Fantasy's Services. </p>
                        <p>   Players agree not to disrupt, overburden, or aid or assist in the disruption or overburdening of (a) any computer or server used to offer or support NLG Fantasy or the NLG Fantasy Services (each a "Server"); or (2) the enjoyment of NLG Fantasy Services by any other Player or person. </p>
                        <p>   Players agree not to institute, assist or become involved in any type of attack, including without limitation to distribution of a virus, denial of service, or other attempts to disrupt NLG Fantasy Services or any other person's use or enjoyment of NLG Fantasy Services. </p>
                        <p>   Players shall not attempt to gain unauthorised access to the Player accounts, Servers or networks connected to NLG Fantasy Services by any means other than the Player interface offered by NLG Fantasy, including but not limited to, by circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other person to circumvent or modify, any security, technology, device, or software that underlies or is part of NLG Fantasy Services. </p>
                        <p>   Without limiting the foregoing, Players agree not to use NLG Fantasy for any of the following: </p>

                        <p>   To engage in any obscene, offensive, indecent, racial, communal, anti-national, objectionable, defamatory or abusive action or communication; </p>
                        <p>   To harass, stalk, threaten, or otherwise violate any legal rights of other individuals; </p>
                        <p>   To publish, post, upload, e-mail, distribute, or disseminate (collectively, "Transmit") any inappropriate, profane, defamatory, infringing, obscene, indecent, or unlawful content; </p>
                        <p>   To Transmit files that contain viruses, corrupted files, or any other similar software or programs that may damage or adversely affect the operation of another person's computer, NLG Fantasy, any software, hardware, or telecommunications equipment; </p>
                        <p>   To advertise, offer or sell any goods or services for any commercial purpose on NLG Fantasy without the express written consent of NLG Fantasy; </p>
                        <p>   To Transmit content regarding services, products, surveys, contests, pyramid schemes, spam, unsolicited advertising or promotional materials, or chain letters; </p>
                        <p>   To advertise, offer or sell any goods or services for any commercial purpose on NLG Fantasy without the express written consent of NLG Fantasy; </p>
                        <p>   To Transmit content regarding services, products, surveys, contests, pyramid schemes, spam, unsolicited advertising or promotional materials, or chain letters; </p>
                        <p>   To download any file, recompile or disassemble or otherwise affect our products that you know or reasonably should know cannot be legally obtained in such manner; </p>
                        <p>   To falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or the source of software or other material; </p>
                        <p>   To restrict or inhibit any other player from using and enjoying any public area within our sites; </p>
                        <p>   To collect or store personal information about other Players; </p>
                        <p>   To interfere with or disrupt NLG Fantasy, servers, or networks; </p>
                        <p>   To impersonate any person or entity, including, but not limited to, a representative of NLG Fantasy, or falsely state or otherwise misrepresent Player's affiliation with a person or entity; </p>
                        <p>   To forge headers or manipulate identifiers or other data in order to disguise the origin of any content transmitted through NLG Fantasy or to manipulate Player's presence on NLG Fantasy; </p>
                        <p>   To take any action that imposes an unreasonably or disproportionately large load on our infrastructure; </p>
                        <p>   To engage in any illegal activities. You agree to use our bulletin board services, chat areas, news groups, forums, communities and/or message or communication facilities (collectively, the "Forums") only to send and receive messages and material that are proper and related to that particular Forum. </p>

                        <p>   If a Player chooses a username that, in NLG Fantasy's considered opinion is obscene, indecent, abusive or that might subject NLG Fantasy to public disparagement or scorn, NLG Fantasy reserves the right, without prior notice to the Player, to change such username and intimate the Player or delete such username and posts from NLG Fantasy, deny such Player access to NLG Fantasy, or any combination of these options. </p>
                        <p>   Unauthorized access to NLG Fantasy is a breach of these Terms and Conditions, and a violation of the law. Players agree not to access NLG Fantasy by any means other than through the interface that is offered by NLG Fantasy for use in accessing NLG Fantasy. Players agree not to use any automated means, including, without limitation, agents, robots, scripts, or spiders, to access, monitor, or copy any part of our sites, except those automated means that we have approved in advance and in writing. </p>
                        <p>   Use of NLG Fantasy is subject to existing laws and legal processes. Nothing contained in these Terms and Conditions shall limit NLG Fantasy's right to comply with governmental, court, and law-enforcement requests or needs relating to Players' use of NLG Fantasy. </p>
                        <p>   Players may reach out to NLG Fantasy through Support Team. </p>  
                        <p>   NLG Fantasy may not be alleged responsible for any content contributed by Players on the NLG Fantasy. </p>

 
                        </div>
                      </div>
                    </li>

                    <li>
                      <h3 id="NLG Fantasy">       Contests, Participation and Prizes    </h3>
                      <div class="t_c_content">
                        <div class="pdLt20">
                          <p> Currently, there are paid versions of the Contests made available by NLG Fantasy Players may participate in the Contests by paying the pre-designated amount as provided on the relevant Contest page. The Participant with the highest aggregate points at the end of the pre-determined round shall be eligible to win a pre-designated prize, as stated on the relevant Contests page. </p>
                          <p> A Participant may create different Teams for participation in Contests offered in relation to a Fantasy Sport Event across the NLG Fantasy Services. However, unless NLG Fantasy specifies otherwise in relation to any Contest ("Multiple Entry Contest"), Participants acknowledge and agree that they may enter only one Team in any Contest offered in relation to a Fantasy Sport Event. In the case of a Multiple Entry Contest, a Participant may enter more than one Team in a single Multiple Entry Contest, however on submitting more than one Team for participation in a single Multiple Entry Contest, the Participant will not be permitted to edit or revise the Teams so submitted for participation in such Multiple Entry Contest. In addition, it is expressly clarified that NLG Fantasy may, from time to time, restrict the maximum number of Teams that may be created by a single Player account (for each format of the NLG Fantasy Services) or which a single Player account may enter in a particular Multiple Entry Contest, in each case to such number as determined by NLG Fantasy in its sole discretion. </p>
                          <p> NLG Fantasy shall collect a pre-designated fee for access to the NLG Fantasy Services from each Participant in relation to the Contests. </p>
                          <p> In the event a Participant indicates, while entering an address, that he/she is a resident of either Andhra Pradesh, Assam, Nagaland, Sikkim and Telangana such Participant will not be permitted to proceed to sign up for the round or contest and may not participate in any paid version of the Contests. </p>

                        </div>
                      </div>
                    </li>

                    <li>
                      <h3 id="NLG Fantasy">       Contest Formats</h3>
                      <div class="t_c_content">
                        <div class="pdLt20">
                            <p>
                            NLG Fantasy offers Contests. in two separate formats of NLG Fantasy Services, (1) as a public contest where Players can participate in a Contest with other Players without any restriction on participation and (2) private contests, where Players can invite specific Players into a Contest and restrict participation to such invited Players. All rules applicable to Contests as set out herein shall be applicable to both formats of the Contests.
                            </p>
                        </div>
                      </div>
                    </li>


                    <li>
                      <h3 id="NLG Fantasy"> Public contest    </h3>
                      <div class="t_c_content">
                        <div class="pdLt20">
                          <p> In the Public contest format of the Contests, NLG Fantasy may offer the Contests in contests comprising of 2, 3, 5, 10, 100 Participants or any other pre-designated number of Participants. </p>
                          <p>  NLG Fantasy may offer this format of the Contests as a paid format and the Winner will be determinable at the end of the round.</p>
                          <p>  The number of Participants required to make the Contests operational will be pre-specified and once the number of Participants in such Contests equals the pre-specified number required for that Contests, such Contests shall be operational. In case the number of Participants is less than the pre-specified number at the time of commencement of the round, such Contests will not be operational and the participation fee paid by each Participant shall be returned to the account of such Player without any charge or deduction.</p>
                          <p>  In certain Contests across the NLG Fantasy Services, designated as "Confirmed contests", the Contests shall become operational once the number of Participants in such Contest s equals the pre-specified number of winners to be declared in such Contests, even if all available Participant slots (as pre-specified in relation to the Contests) remain unfilled. It is clarified that notwithstanding the activation of such Contests, Participants can continue to join such Contests till either (i) all available Participant slots of such Contests are filled or (ii) the round to which the Contest s relates commences, whichever is earlier. In case such Contests is not operational by the time of the commencement of the round, the participation fee paid by each Users shall be returned to the account of such Player without any charge or deduction. </p>
                        </div>
                      </div>
                    </li>


                      <li>
                        <h3 id="NLG Fantasy">  Private contest    </h3>
                        <div class="t_c_content">
                          <div class="pdLt20"> 

                            <p> In the Private contest format of the Contests, NLG Fantasy enables Players to create a contest ("Private contest") and invite other players, whether existing Players or otherwise, ("Invited Player") to create Teams and participate in the Contests. Players may create a Private contest to consist of a pre-specified number of Participants, that is, consisting of either 2 Participants, 3 Participants, 5 Participants or 10 Participants. The Player creating the Private contest shall submit the participation fee for such Private contest and thereby join that Private contest, shall supply a name for the Private contest and be offered with a unique identification code ("contest Code") (which will be issued to the account of such Player). The Player agrees and understands that once the Private contest is created no change shall be permitted in the terms or constitution of the Private contest, except for a change in the name of the contest. The Player creating the Private contest shall offer NLG Fantasy with the email address or Facebook account username of Invited Players to enable NLG Fantasy to send a message or mail inviting such Invited Player to register with NLG Fantasy (if necessary) and participate in the Private contest in relation to which the invite has been issued. </p>
                            <p> In order to participate in the Private contest, an Invited Player shall input the contest Code associated with the Private contest and submit the participation fee for the Private contest. Once the number of Participants in a Private contest equals the number of pre-specified Participants for that Private contest, the Private contest shall be rendered operative and no other Invited Players or Players shall be permitted to participate in the Private contest. In the event that any Private contest does not contain the pre-specified number of Participants for that Private contest within 1 hour prior to the commencement of the round/Contest, the Participants of such Private contest will be offered with the option to convert the Private contest into a Public contest format, and permit the participation of Players without the contest Code. It is clarified that NLG Fantasy undertakes such conversion in a serialised manner and cannot and does not warrant that any Private contest will be converted into a Public contest format prior to the commencement of the round/Contests or that any Players will join such Contest (s to make it operational. In case the number of Participants in any Private contest (or converted Contests) is less than the pre-specified number at the time of commencement of the round, such Contests will not be operational and the participation fee paid by each Player shall be returned to the account of such Player without any charge or deduction. </p>
                            <p> It is clarified that the participation of Invited Players in any Private contest is subject to the pre-specified number of Participants for that Private contest, and NLG Fantasy shall not be liable to any person for the inability of any Invited Player to participate in any Private contest due to any cause whatsoever, including without limitation due to a hardware or technical malfunction or lack of eligibility of such Invited Player to participate in the Contests.</p>
 
                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="NLG Fantasy">  Eligibility   </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          <p> The Contests are open only to persons above the age of 18 years. </p>
                          <p> The Contests are open only to persons, currently residing in India. </p>
                          <p>  NLG Fantasy may, in accordance with the laws prevailing in certain Indian states, bar individuals residing in those states from participating in the Contests. Currently, individuals residing in the Indian states of Andhra Pradesh, Assam, Nagaland, Sikkim and Telangana may not participate in the paid version of the Contest as the laws of these states bar persons from participating in games of skill where participants are required to pay to enter. </p>
                          <p>  Persons who wish to participate must have a valid email address. </p>
                          <p>  Only those Participants who have successfully registered on the NLG Fantasy as well as registered prior to each round in accordance with the procedure outlined above shall be eligible to participate in the Contest and win prizes. </p>

 
                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="NLG Fantasy">  Payment Terms and Players Fund  </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                            <p>
                            In respect of any transactions at NLG Fantasy, including making a payment to participate in the paid versions of Contests, Players agree to be bound by the following payment terms:


                            </p>
                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="NLG Fantasy">  All transactions on www.nlgfantasy.com shall be in Indian Rupees.     </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">

                           <p>  Once users register on our Portal, we maintain a user account for players to keep a record of all their transactions with us. Payments connected with participation in Cash Games have to be made through their NLG Fantasy user account. All cash prizes won by players are credited by us into this user account. </p>
                           <p>  When making a payment, please ensure that the gadget used to make the payment is your own and is used to “Deposit” into user account only. </p>
                            <p>  Subject to the Deposit limits specified by us from time to time, you are free to deposit as much money as you want in your user account for the purpose of participating in Cash Games at www.nlgfantasy.com. </p>
                            <p>  NLG Fantasy wants you to play responsibly here. The ability to Deposit in your user account shall be subject to monthly Deposit limits which we can be set by us with undertakings, indemnity, waiver and verification conditions as we deem appropriate in our sole discretion. </p>
                            <p>  Credit card, Debit card, prepaid cash cards and internet banking payments are processed through third party payment gateways. Similarly, other payment modes also require an authorization by the intermediary which processes payments. We are not responsible for delays or denials at their end and processing of payments will be solely in terms of their policies and procedures without any responsibility or risk at our end. If there are any issues in connection with depositing, a complaint may be sent to us following the complaints procedure provided in "Complaints and disputes" section below. You agree that in such an event of your credit being delayed or eventually declined for reasons beyond our control, we will not be held liable in any manner whatsoever. Once a payment/transaction is authorized, the funds are credited to your user account and are available for you to play Cash Games. </p>
                            <p>  We have the right to cancel a transaction at any point of time solely according to our discretion in which case if the payment is successful, then the transaction will be reversed and the money credited back to your payment instrument. </p>
                            <p>  Player funds are held in trust by us in specified bank accounts. Funds held in your user account are held separately from our corporate funds. Even in the highly unlikely event of an insolvency proceeding, you claims on the deposits will be given preference over all other claims to the extent permissible by law. </p>

                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="NLG Fantasy">  Withdrawals   </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                            <p> You may withdraw your winnings by means of either an account payee cheque or an electronic bank to bank transfer for the amount of winnings. </p>
                            <p> You agree that all withdrawals you make are governed by the following conditions: </p>
                            <p> www.nlgfantasy.com can ask you for KYC documents to verify your address and identity at any stage. Withdrawals will be permitted only from accounts for which such KYC process is complete. </p>
                            <p> You can choose to withdraw money from your user account at any time, subject to bonus/prize money withdrawal restrictions, by notifying us of your withdrawal request. </p>
                            <p> Withdrawal of cash immediately after cash deposit to NLG Fantasy user account is barred. Playing fantasy sports is essential before withdrawing your winnings. </p>
                            <p> Once notified, post verification of the withdrawal request, we may disburse the specified amount by cheque or electronic transfer based on the mode of withdrawal selected by you. We shall make our best efforts to honor your choice on the mode of withdrawal, but reserve the right to always disburse the specified amount to you by cheque. We also reserve the right to disburse the amount on the financial instrument used to Deposit to your user account. </p>
                            <p> We will attempt our best to process your withdrawals in a timely manner, but there could be delays due to the time required for verification and completing the withdrawal transaction. We shall not be liable to pay you any form of compensation for the reason of delays in remitting payments to you from your user account. </p>
                            <p> To be eligible to win a prize, you must be a resident of India and accessing the Services of com from India. </p>
                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="NLG Fantasy">  Tabulation of fantasy points     </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                            <p>
                            NLG Fantasy may obtain the score feed and other information required for the computation and tabulation of fantasy points from third party service providers. In the rare event that any error in the computation or tabulation of fantasy points, selection of winners, etc., as a result of inaccuracies in or incompleteness of the feed provided by the third party service provider comes to its attention, NLG Fantasy shall use best efforts to rectify such error prior to the distribution of prizes. However, NLG Fantasy hereby clarifies that it relies on the accuracy and completeness of such third party score/statistic feeds and does not itself warrant or make any representations concerning the accuracy thereof and, in any event, shall take no responsibility for inaccuracies in computation and tabulation of fantasy points or the selection of winners as a result of any inaccurate or incomplete scores/statistics received from such third party service provider. Players and Participants agree not to make any claim or raise any complaint against NLG Fantasy in this respect. Selection and Verification of Winners and Conditions relating to the Prizes
                            </p>
                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="NLG Fantasy">  Selection of Winners   </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                            <p>
                            Winners will be decided on the basis of the scores of the Teams in a designated round (which may last anywhere between one day and an entire tournament) of the Contests. The Participants owning the Teams with the highest aggregate score in a particular round shall be declared the Winners. In certain pre-specified Contests, NLG Fantasy may declare more than one Winner and distribute prizes to such Winners in increasing order of their Team's aggregate score at the end of the designated round of the Contest. The contemplated number of Winners and the prize due to each Winner in such Contest shall be as specified on the Contest page prior to the commencement of the Contest.
                            </p>
                            <p>
                            Participants creating Teams on behalf of any other Participant or person shall be disqualified.
In the event of a tie, the winning Participants shall be declared Winners and the prize shall be equally divided among such Participants.

                            </p>
                            <p>
                            NLG Fantasy shall not be liable to pay any prize if it is discovered that the Winners have not abided by these Terms and Conditions, and other rules and regulations in relation to the use of the NLG Fantasy, Contest, "Fantasy Rules", etc.
                            </p>
                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="NLG Fantasy">    Contacting Winners  </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          <p>  Winners shall be contacted by NLG Fantasy or the third party conducting the Contest on the e-mail address provided at the time of registration. The verification process and the documents required for the collection of prize shall be detailed to the Winners at this stage. As a general practice, winners will be required to provide following documents:</p>
                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="NLG Fantasy">  Player's PAN card;   </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          <p>   A government-issued residence proof; Player's bank account details and proof of the same </p> 

                          <p>   NLG Fantasy shall not permit a Winner to withdraw his/her prizes/accumulated winnings unless the above-mentioned documents have been received and verified within the time-period stipulated by NLG Fantasy. The Player represents and warrants that the documents provided in the course of the verification process are true copies of the original documents to which they relate.</p> 
                          <p>   Participants are required to provide proper and complete details at the time of registration. NLG Fantasy shall not be responsible for communications errors, commissions or omissions including those of the Participants due to which the results may not be communicated to the Winner.</p> 
                          <p>   The list of Winners shall be posted on a separate web-page on the NLG Fantasy. The winners will also be intimated by e-mail.</p> 
                          <p>   In the event that a Participant has been declared a Winner on the abovementioned web-page but has not received any communication from NLG Fantasy, such Participant may contact NLG Fantasy within the time specified on the webpage.</p> 

                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="NLG Fantasy">    Verification process  </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          <p>    Only those Winners who successfully complete the verification process and provide the required documents within the time limit specified by NLG Fantasy shall be permitted to withdraw/receive their accumulated winnings (or any part thereof). NLG Fantasy shall not entertain any claims or requests for extension of time for submission of documents.</p>

                          <p>
                          NLG Fantasy shall scrutinise all documents submitted and may, at its sole and absolute discretion, disqualify any Winner from withdrawing his accumulated winnings (or any part thereof) on the following grounds:
                          </p>
                          <p>
                          Determination by NLG Fantasy that any document or information submitted by the Participant is incorrect, misleading, false, fabricated, incomplete or illegible; or Participant does not fulfil the Eligibility Criteria specified in Clause 10 above; or
Any other ground.

                          </p>
                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="NLG Fantasy"> Service Charge and Service Tax  </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                            <p> Currently NLG Fantasy service fees are range bound between 3% to 5%, depending on denomination of contests. Games higher than Rs. 999 may attract flat 3% service fees, while 5% may be levied on any amount lower. </p>
                            <p> www.nlgfantasy.com reserves the right to levy different service charges for different games/tournaments at its discretion, also We reserve the right to change the levied service charges from time to time, however We would duly inform of the changes by posting a notice as and when applicable and feasible. </p>
                            <p> In addition, to service charges, we may charge service tax with applicable cesses or levies as mandated by applicable laws in India. 
                            </p>
                           
                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="NLG Fantasy">    TDS:   </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          <p>  All prizes shall be subject to deduction of tax (“TDS”) as per the prevailing provisions of Income Tax Act 1961 as may be amended from time to time. In case of any revisions by the Government of India to the concerned deduction in the future, TDS will be deducted by NLG Fantasy in accordance with the then current prescribed TDS rate. The Winners shall be responsible for payment of any other applicable taxes, including but not limited to, income tax, gift tax, etc. in respect of the prize money. </p>

                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="NLG Fantasy">   Miscellaneous   </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                              <p>    The decision of NLG Fantasy with respect to the awarding of prizes shall be final, binding and non-contestable. </p>
                              <p>  Participants playing the paid formats of the Contests confirm that they are not residents of any of the following Indian states - Andhra Pradesh, Assam, Nagaland, Sikkim and Telangana. If it is found that a Participant playing the paid formats of the Contests is a resident of any of the abovementioned states, NLG Fantasy shall disqualify such Participant and forfeit any prize won by such Participant. Further NLG Fantasy may, at its sole and absolute discretion, suspend or terminate such Participant's account with NLG Fantasy. Any amount remaining unused in the Player's Game Account or Winnings Account on the date of deactivation or deletion shall be reimbursed to the Player by an online transfer to the Player's bank account on record with NLG Fantasy, subject to the processing fee (if any) applicable on such transfers as set out herein. </p>
                              <p>  If it is found that a Participant playing the paid formats of the Contests is under the age of eighteen (18), NLG Fantasy shall be entitled, at its sole and absolute discretion, to disqualify such Participant and forfeit his/her prize. Further, NLG Fantasy may, at its sole and absolute discretion, suspend or terminate such Participant's account. </p>
                              <p>  To the extent permitted by law, NLG Fantasy makes no representations or warranties as to the quality; suitability or merchantability of any prizes and shall not be liable in respect of the same. </p>
                              <p>  NLG Fantasy may, at its sole and absolute discretion, vary or modify the prizes being offered to winners. Participants shall not raise any claim against NLG Fantasy or question its right to modify such prizes being offered, prior to closure of the Contest. </p>
                              <p>  NLG Fantasy will not bear any responsibility for the transportation or packaging of prizes to the respective winners. NLG Fantasy shall not be held liable for any loss or damage caused to any prizes at the time of such transportation. </p>
                              <p>  The Winners shall bear the shipping, courier or any other delivery cost in respect of the prizes. </p>
                              <p>  The Winners shall bear all transaction charges levied for delivery of cash prizes. </p>
                              <p>  All prizes are non-transferable and non-refundable. Prizes cannot be exchanged / redeemed for cash or kind. No cash claims can be made in lieu of prizes in kind. </p>
                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="NLG Fantasy">  Publicity   </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">

                          <p>  Acceptance of a prize by the Winner constitutes permission for NLG Fantasy, and its affiliates to use the Winner's name, likeness, voice and comments for advertising and promotional purposes in any media worldwide for purposes of advertising and trade without any further permissions or consents and / or additional compensation whatsoever. </p>
                          <p>  The Winners further undertake that they will be available for promotional purposes as planned and desired by NLG Fantasy without any charge. The exact dates remain the sole discretion of NLG Fantasy. Promotional activities may include but not be limited to press events, internal meetings and ceremonies/functions. </p>
                          <p>  General Conditions </p>
                          <p>  If it comes to the notice of NLG Fantasy that any governmental, statutory or regulatory compliances or approvals are required for conducting any Contests or if it comes to the notice of NLG Fantasy that conduct of any such Contests is prohibited, then NLG Fantasy shall withdraw and / or cancel such Contests without prior notice to any Participants or winners of any Contests. Players agree not to make any claim in respect of such cancellation or withdrawal of the Contest, or contest it in any manner. </p>
                          <p>  Employees, directors, affiliates, relatives and family members of NLG Fantasy, will not be eligible to participate in any Contests. </p>
                          <p>  Dispute and Dispute Resolution </p>
                          <p>  The courts of competent jurisdiction at Kolkata shall have exclusive jurisdiction to determine any and all disputes arising out of, or in connection with, the NLG Fantasy Services provided by NLG Fantasy (including the Contests), the construction, validity, interpretation and enforceability of these Terms and Conditions, or the rights and obligations of the Players (including Participants) or NLG Fantasy, as well as the exclusive jurisdiction to grant interim or preliminary relief in case of any dispute referred to arbitration as given below. All such issues and questions shall be governed and construed in accordance with the laws of the Republic of India. </p>
                          <p>  In the event of any legal dispute (which may be a legal issue or question) which may arise, the party raising the dispute shall provide a written notification ("Notification") to the other party. On receipt of Notification, the parties shall first try to resolve the dispute through discussions. In the event that the parties are unable to resolve the dispute within fifteen (15) days of receipt of Notification, the dispute shall be settled by arbitration. </p>
                          <p>  The place of arbitration shall be Kolkata, India. All arbitration proceedings shall be conducted in English and in accordance with the provisions of the Arbitration and Conciliation Act, 1996, as amended from time to time. </p>
                          <p>  The arbitration award will be final and binding on the Parties, and each Party will bear its own costs of arbitration and equally share the fees of the arbitrator unless the arbitral tribunal decides otherwise. The arbitrator shall be entitled to pass interim orders and awards, including the orders for specific performance and such orders would be enforceable in competent courts. The arbitrator shall give a reasoned award. </p>
                          <p>  Nothing contained in these Terms and Conditions shall prevent NLG Fantasy from seeking and obtaining interim or permanent equitable or injunctive relief, or any other relief available to safeguard NLG Fantasy's interest prior to, during or following the filing of arbitration proceedings or pending the execution of a decision or award in connection with any arbitration proceedings from any court having jurisdiction to grant the same. The pursuit of equitable or injunctive relief shall not constitute a waiver on the part of NLG Fantasy to pursue any remedy for monetary damages through the arbitration described herein. </p>




                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="NLG Fantasy">   Miscellaneous    </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                            <p>   NLG Fantasy may be required under certain legislations, to notify Players of certain events. Players hereby acknowledge and consent that such notices will be effective upon NLG Fantasy posting them on the NLG Fantasy or delivering them to the Player through the email address provided by the Player at the time of registration. Players may update their email address by logging into their account on the NLG Fantasy. If they do not provide NLG Fantasy with accurate information, NLG Fantasy cannot be held liable for failure to notify the Player. </p>
                            <p>  NLG Fantasy shall not be liable for any delay or failure to perform resulting from causes outside its reasonable control, including but not limited to any failure to perform due to unforeseen circumstances or cause beyond NLG Fantasy's control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, network infrastructure failures, strikes, or shortages of transportation facilities, fuel, energy, labor or materials or any cancellation of any cricket/football/kabaddi match to which a Contest relates. In such circumstances, NLG Fantasy shall also be entitled to cancel any related Contests and to process an appropriate refund for all Participants.</p>
                            <p>  NLG Fantasy's failure to exercise or enforce any right or provision of these Terms and Conditions shall not constitute a waiver of such right or provision.</p>
                            <p>  Players agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the NLG Fantasy or these Terms must be filed within thirty (30) days of such claim or cause of action arising or be forever barred.</p>
                            <p>  These Terms and Conditions, including all terms, conditions, and policies that are incorporated herein by reference, constitute the entire agreement between the Players and NLG Fantasy Gaming Private Limited and govern your use of the NLG Fantasy, superseding any prior agreements that any Player may have with NLG Fantasy Gaming Private Limited.</p>
                            <p>  If any part of these Terms and Conditions is determined to be indefinite, invalid, or otherwise unenforceable, the rest of these Terms and Conditions shall continue in full force.</p>
                            <p>  NLG Fantasy reserves the right to moderate, restrict or ban the use of the NLG Fantasy, specifically to any Player, or generally, in accordance with NLG Fantasy's policy/policies from time to time, at its sole and absolute discretion and without any notice.</p>
                            <p>  NLG Fantasy may, at its sole and absolute discretion, permanently close or temporarily suspend any NLG Fantasy Services (including any Contests).</p>
                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="NLG Fantasy">   Grievance redressal mechanism:     </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          <p>In case you have any complaints or grievance, please share the same with us by writing to  <a href="mailto:contact@nlgfantasy.com">contact@nlgfantasy.com </a></p>
                          <p>
                          In your complaint or grievance, please include the following information:
                          </p>
                          <p> Description of the complaint or grievance with clear identification of the User Content in relation to which such complaint or grievance is made; A statement that the information provided in the complaint or grievance is accurate. </p>
                          <p> On receiving such complaint, grievance or notice, the Company reserves the right to investigate and/or take such action as the Company may deem appropriate. The Company may reach out to you to seek further clarification or assistance with the investigation, or verify the statements made in your complaint, grievance or notice, and you acknowledge that your timely assistance with the investigation would facilitate the redressal of the same.</p>

                         
                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="NLG Fantasy">  Legality of game of skill:    </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          <p>  Games of skill are legal, as they are excluded from the ambit of Indian gambling legislations including, the Public Gambling Act of 1867. The Hon’ble Supreme Court in the cases of State of Andhra Pradesh v. K Satyanarayana (AIR 1968 SC 825) and KR Lakshmanan v. State of Tamil Nadu (AIR 1996 SC 1153) has held that a game in which success depends predominantly upon the superior knowledge, training, attention, experience and adroitness of the player shall be classified as a game of skill. </p>
                          <p> Fantasy sports have been held to be a game of skill by the High Courts of Punjab &amp; Haryana and Rajasthan and the decisions of the said High Courts have been affirmed by the Hon’ble Supreme Court. </p>
                          <p> The Fantasy Sports Contests held on theNLG Fantasy platform are games of skill as success of Players depends primarily on their superior knowledge of the games of cricket and/or football and/or basketball and/or kabaddi and/or baseball statistics, knowledge of players' relative form, attention and dedication towards the Contests and adroitness in playing the Contests. The Contests also requires Players to field well-balanced sides with limited resources and make substitutions at appropriate times to gain the maximum points. </p>
                          <p> By participating in this Contests, each Player acknowledges and agrees that he/she is participating in a game of skill. </p>
                          <p> The customers are advised to utilise/withdraw the amount so advanced to the Company in the ordinary course of business, for the sole purpose of participating in any of the online gaming platforms of the Company within the continuous period of 334 days of such advances, unless utilised either in full or in part by participating in any of the online gaming platforms of the Company, failing which such advances from customers will be forfeited on the 335th day without giving any advance notice/intimation to the customer and the customers will thereafter lose all its claims on such monies as advanced and remained remain continuously unutilised without any recourse to the Company. </p>

                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="NLG Fantasy">   Release and limitation of liability:     </h3>
                        <div class="t_c_content">
                          <div class="pdLt20"> 
                            <p> Users shall access the NLG Fantasy Services provided on NLG Fantasy voluntarily and at their own risk. NLG Fantasy shall, under no circumstances be held responsible or liable on account of any loss or damage sustained (including but not limited to any accident, injury, death, loss of property) by Users or any other person or entity during the course of access to the NLG Fantasy Services (including participation in the Contest(s)) or as a result of acceptance of any prize. </p>
                            <p> By entering the contests and accessing the NLG Fantasy Services provided therein, Users hereby release from and agree to indemnify NLG Fantasy, and/ or any of its directors, employees, partners, associates and licensors, from and against all liability, cost, loss or expense arising out their access to the NLG Fantasy Services including (but not limited to) personal injury and damage to property and whether direct, indirect, consequential, foreseeable, due to some negligent act or omission on their part, or otherwise. </p>
                            <p> NLG Fantasy accepts no liability, whether jointly or severally, for any errors or omissions, whether on behalf of itself or third parties in relation to the prizes. </p>
                            <p> Users shall be solely responsible for any consequences which may arise due to their access of NLG Fantasy Services by conducting an illegal act or due to non-conformity with these Terms and Conditions and other rules and regulations in relation to NLG Fantasy Services, including provision of incorrect address or other personal details. Users also undertake to indemnify NLG Fantasy and their respective officers, directors, employees and agents on the happening of such an event (including without limitation cost of attorney, legal charges etc.) on full indemnity basis for any loss/damage suffered by NLG Fantasy on account of such act on the part of the Users. </p>
                            <p> Users shall indemnify, defend, and hold NLG Fantasy harmless from any third party/entity/organization claims arising from or related to such User's engagement with the NLG Fantasy or participation in any Contest. In no event shall NLG Fantasy be liable to any User for acts or omissions arising out of or related to User's engagement with the NLG Fantasy or his/her participation in any Contest(s). </p>
                            <p> In consideration of NLG Fantasy allowing Users to access the NLG Fantasy Services, to the maximum extent permitted by law, the Users waive and release each and every right or claim, all actions, causes of actions (present or future) each of them has or may have against NLG Fantasy, its respective agents, directors, officers, business associates, group companies, sponsors, employees, or representatives for all and any injuries, accidents, or mishaps (whether known or unknown) or (whether anticipated or unanticipated) arising out of the provision of NLG Fantasy Services or related to the Contests or the prizes of the Contests. </p>
 
                          </div>
                        </div>
                      </li>

                      <li>
                        <h3 id="NLG Fantasy">   Disclaimer  </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                            <p> To the extent permitted under law, neither NLG Fantasy nor its parent/holding company, subsidiaries, affiliates, directors, officers, professional advisors, employees shall be responsible for the deletion, the failure to store, the mis-delivery, or the untimely delivery of any information or material. </p>
                            <p> To the extent permitted under law, NLG Fantasy shall not be responsible for any harm resulting from downloading or accessing any information or material, the quality of servers, games, products, NLG Fantasy services or sites, cancellation of competition and prizes. NLG Fantasy disclaims any responsibility for, and if a user pays for access to one of NLG Fantasy's Services the user will not be entitled to a refund as a result of, any inaccessibility that is caused by NLG Fantasy's maintenance on the servers or the technology that underlies our sites, failures of NLG Fantasy's service providers (including telecommunications, hosting, and power providers), computer viruses, natural disasters or other destruction or damage of our facilities, acts of nature, war, civil disturbance, or any other cause beyond our reasonable control. In addition, NLG Fantasy does not provide any warranty as to the content on the NLG Fantasy. NLG Fantasy content is distributed on an "as is, as available" basis. </p>
                            <p> Any material accessed, downloaded or otherwise obtained through NLG Fantasy is done at the user's discretion, competence, acceptance and risk, and the user will be solely responsible for any potential damage to user's computer system or loss of data that results from a user's download of any such material. </p>
                            <p> NLG Fantasy shall make best endeavours to ensure that the NLG Fantasy is error-free and secure, however, neither NLG Fantasy nor any of its partners, licensors or associates makes any warranty that:the NLG Fantasy will meet users' requirements, NLG Fantasy will be uninterrupted, timely, secure, or error free the results that may be obtained from the use of NLG Fantasy will be accurate or reliable; and the quality of any products, NLG Fantasy Services, information, or other material that Users purchase or obtain through www.nlgfantasy.com will meet users' expectations. </p>
                            <p> To the extent permitted under law, neither NLG Fantasy nor its partners, licensors or associates shall be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use of or inability to use our sites, even if we have been advised of the possibility of such damages. </p>
                            <p> Any NLG Fantasy Services, events or Contests being hosted or provided, or intended to be hosted on NLG Fantasy platform and requiring specific permission or authority from any statutory authority or any state or the central government, or the board of directors shall be deemed cancelled or terminated, if such permission or authority is either not obtained or denied either before or after the availability of the relevant NLG Fantasy Services, events or Contests are hosted or provided. </p>
                            <p> To the extent permitted under law, in the event of suspension or closure of any Services, events or Contests, Users (including Participants) shall not be entitled to make any demands, claims, on any nature whatsoever. </p>
                          </div>
                        </div>
                      </li>  

                      

                      <li>
                        <h3 id="NLG Fantasy">   Registration  </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">

                          <p>
                          In order to register for the Contests, Players are required to accurately provide the following information:
                          </p>

                            <ul>
                              <li>
                              Full Name
                              </li> 
                              <li>
                              Team Name(s)  
                              </li>
                              <li>
                              E-mail address  
                              </li>
                              <li>
                              Password  
                              </li>
                              <li>
                              State of Residence  
                              </li>
                              <li>
                              Gender  
                              </li>
                              <li>
                              Date of birth  
                              </li>
                            </ul>
                            <p>Players are also required to confirm that they have read, and shall abide by, these Terms and Conditions.</p>
                            <p>
                            In the event a Player indicates, while entering an address, that he/she is a resident of either Andhra Pradesh, Assam, Nagaland, Sikkim and Telangana such Player will not be permitted to proceed to sign up for Contest.
                            </p>
                          </div>
                        </div>
                      </li>  
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}
      {/* <!-- ======= Footer ======= --> */}
      <Footer />
      {/* <!-- End Footer --> */}

      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>
      {/* <div id="preloader"></div> */}
    </>
  );
};
