import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <>
      <header id="header" class="d-flex align-items-center header-transparent">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo">
            <Link to="/">
              <img src="img/logo.svg" alt="" class="img-fluid" />
            </Link>
          </div>
          <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://admin.nlgfantasy.com/downloads">
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <section class="hero p-0 ">
        <div class="hero-main">
          <img
            src="img/hero-bg.png"
            class="img-fluid w-100 desktop-hero d-none d-sm-none d-md-block"
            alt=""
          />
          <img
            src="img/hero-mobile-banner.png"
            class="img-fluid w-100 desktop-hero d-block d-sm-block d-md-none"
            alt=""
          />
        </div>
      </section>
      <main id="main">
        <section id="counts" class="counts">
          <div class="container">
            <div class="row" data-aos="fade-up">
              <div class="col-lg-3 col-md-6">
                <div class="count-box">
                  <i class="bi bi-emoji-smile"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="232"
                    data-purecounter-duration="1"
                    class="purecounter"
                  >
                    1.5 MILLION+
                  </span>
                  <p>Happy Clients</p>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 mt-5 mt-md-0">
                <div class="count-box">
                  <i class="ri-currency-line"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="521"
                    data-purecounter-duration="1"
                    class="purecounter"
                  >
                    125 CRORES+
                  </span>
                  <p>Cash Winnings</p>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
                <div class="count-box">
                  <i class="ri-focus-2-line"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="1463"
                    data-purecounter-duration="1"
                    class="purecounter"
                  >
                    25 LAKHS+
                  </span>
                  <p>Contests Played</p>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
                <div class="count-box">
                  <i class="ri-gift-line"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="15"
                    data-purecounter-duration="1"
                    class="purecounter"
                  >
                    10 THOUSAND+
                  </span>
                  <p>Giveaway</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- ======= About Section ======= --> */}
        <section id="Downloading" class="Downloading pb-0">
          <div class="container">
            <div class="section-title aos-init aos-animate">
              <h2>Downloading the</h2>
              <p>NLG Fantasy App is Easy!</p>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="howToinstallmain">
                  <div class="howToinstall-card">
                    <span class="number-icon">1</span>
                    <img src="img/install01.png" class="img-fluid" alt="" />
                  </div>
                  <h4>
                    Open www.nlgfantasy.com &amp; click on{" "}
                    <strong>“Download for Android”</strong> button
                  </h4>
                </div>
              </div>

              <div class="col-md-4">
                <div class="howToinstallmain">
                  <div class="howToinstall-card">
                    <span class="number-icon">2</span>
                    <img src="img/install02.png" class="img-fluid" alt="" />
                  </div>
                  <h4>
                    Open nlgfantasy.com &amp; Wait for the apk to download
                    & click on<strong>“Open”</strong>
                  </h4>
                </div>
              </div>

              <div class="col-md-4">
                <div class="howToinstallmain">
                  <div class="howToinstall-card">
                    <span class="number-icon">3</span>
                    <img src="img/install03.png" class="img-fluid" alt="" />
                  </div>
                  <h4>
                    Click <strong>“Allow for this Source”</strong> when prompted
                    & then click on Install
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Section --> */}

        <section id="about" class="about">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="section-title aos-init aos-animate">
                  <h2>Enjoy Superfast Winnings with</h2>
                  <p> Super-easy Gaming</p>
                </div>
              </div>

              <div
                class="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch"
                data-aos="fade-right"
              ></div>

              <div
                class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5"
                data-aos="fade-left"
              >
                <div class="icon-box" data-aos="zoom-in" data-aos-delay="100">
                  <div class="icon">
                    <i class="">1</i>
                  </div>
                  <h4 class="title">
                    <a href="">Select A Match</a>
                  </h4>
                  <p class="description">
                    Choose an upcoming match that you want to play{" "}
                  </p>
                </div>
                <div class="icon-box" data-aos="zoom-in" data-aos-delay="200">
                  <div class="icon">
                    <i class="">2</i>
                  </div>
                  <h4 class="title">
                    <a href=""> Create Team</a>
                  </h4>
                  <p class="description">
                    Use your skills to pick the right players
                  </p>
                </div>

                <div class="icon-box" data-aos="zoom-in" data-aos-delay="300">
                  <div class="icon">
                    <i class="">3</i>
                  </div>
                  <h4 class="title">
                    <a href="">Join Contest</a>
                  </h4>
                  <p class="description">
                    Choose between different contests and win money
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="welcome-sec pb-0">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-6 order-md-2">
                <div class="content">
                  <div class="heading">
                    <h2>
                      A Super <span class="head-text">Welcome Bonus</span>
                    </h2>
                    <h6>DOWNLOAD &amp; REGISTER TO GET</h6>
                    <h5>
                      <span>₹100</span> Sign up bonus
                    </h5>
                  </div>
                  <div class="welcome_btn mt-4 mb-3">
                    <a href="#">
                      <img
                        src="img/andrid-btn.png"
                        class="img-fluid w-100"
                        alt=""
                      />
                    </a>
                    <a href="#">
                      <img
                        src="img/apple-btn.png"
                        class="img-fluid w-100"
                        alt=""
                      />
                    </a>
                  </div>
                  <p>
                    <small>
                      *This game involves an element of financial risk and may
                      be addictive. please play responsibly and at your own
                      risk.
                    </small>
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="welcome_img">
                  <img src="img/about-img.png" class="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

 



        {/* <!-- ======= F.A.Q Section ======= --> */}
        <section id="faq" class="faq section-bg">
          <div class="container">
            <div class="section-title" data-aos="fade-up">
              <h2>F.A.Q</h2>
              <p>Frequently Asked Questions</p>
            </div>

            <div class="faq-list">
                    <ul>
                      <li data-aos="fade-up">
                        <a
                          data-bs-toggle="collapse"
                          class="collapse"
                          data-bs-target="#faq-list-1"
                        >
                        What do you understand by& Fantasy cricket? 
                          <i class="bx bx-chevron-down icon-show"></i>
                          <i class="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-1"
                          class="collapse show"
                          data-bs-parent=".faq-list"
                        >
                          <p>
                          It is a virtual cricket game where you can select a team of 11 players based on your own preferences. Select your exclusive team based on the past performances, toss results, physical fitness, weaknesses, and inabilities, and make your team. You will become a fantasy league player if you have good logic, expert thinking skills, and extensive research. You can play fantasy cricket leagues or fantasy football leagues.	
                          </p>
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="100">
                        <a
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-list-2"
                          class="collapsed"
                        >
                          What is the popularity of Fantasy cricket in India?
                          <i class="bx bx-chevron-down icon-show"></i>
                          <i class="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-2"
                          class="collapse"
                          data-bs-parent=".faq-list"
                        >
                          <p>
                          Indians are completely fanatic about cricket. Cricket is in their soul. It's not only a game, rather its an emotion. The 2019 estimation of the fantasy game users in India showed a projection of around $5billion. Since it’s a mind game and gives Indians the opportunity of relieving their on-field glory, hence the majority of the young sports lovers prefer fantasy cricket in India
                          </p>
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="200">
                        <a
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-list-3"
                          class="collapsed"
                        >
                          How to play Fantasy sports and win real cash? 
                          <i class="bx bx-chevron-down icon-show"></i>
                          <i class="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-3"
                          class="collapse"
                          data-bs-parent=".faq-list"
                        >
                          <p>
                          It’s a very easy process. To set up your dream team follow these simple steps: 
                            <ul className="mt-3" >
                          <li className="p-0" >  -Choose a match from the dashboard that interests you. </li>
                          <li className="p-0">  -Select the good players and create a team of 11 players. </li>
                          <li className="p-0"> -Select a good captain and vice-captain. </li>
                          <li className="p-0"> -Enter the contest by choosing the offers</li>
                          <li className="p-0"> -Follow the live match and track your progress. </li>
                          <li className="p-0"> -As soon as the live match is over and the winning team is declared, you’ll get
                            to view the final ranking and winning amount on your dashboard. </li>
                            </ul>

                          </p>
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="300">
                        <a
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-list-4"
                          class="collapsed"
                        >
                          Is it safe to provide bank details on Fantasy sports apps?
                          <i class="bx bx-chevron-down icon-show"></i>
                          <i class="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-4"
                          class="collapse"
                          data-bs-parent=".faq-list"
                        >
                          <p>
                          Fantasy leagues played on a completely safe platform. You can trust the payment methods 100%. When you enter the game, the websites will ask for your PAN and bank details. you’ll be receiving a certain amount of joining bonus and referral bonuses as well, hence you need to provide the correct bank details on the portal. 
                          </p>
                          <p>
                            <small>
                            Note: Don’t save any card details including CVV and PAN details while making any kind of payment.
                            </small>
                          </p>
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="400">
                        <a
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-list-4a"
                          class="collapsed"
                        >
                          Are Fantasy sports legal in India?
                          <i class="bx bx-chevron-down icon-show"></i>
                          <i class="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-4a"
                          class="collapse"
                          data-bs-parent=".faq-list"
                        >
                          <p>
                          Online fantasy cricket in India is a safe, secure, and completely legal game. The playing procedure of fantasy sports doesn’t match that of the provisions laid under the Public Gambling Act 1867. Hence, it is a “mere game of skill” and not a gambling act. Hence it is completely legitimate. Article 19 (1) of the Constitution of India has declared the same to be legal and safe. It is 100% legal in India. Enjoy a hassle-free match.
                          </p>
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="400">
                        <a
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-list-5"
                          class="collapsed"
                        >
                          Are Fantasy sports banned in a few states of India?
                          <i class="bx bx-chevron-down icon-show"></i>
                          <i class="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-5"
                          class="collapse"
                          data-bs-parent=".faq-list"
                        >
                          <p>
                          Yes. Fantasy games are restricted in few states of India like Assam, Odisha, Sikkim, Nagaland, and Telangana. Although the Indian Constitution has declared daily fantasy games as legal, however, the state governments of different states of India sets out the rules for the daily fantasy games. Hence, any person trying to register in these apps from the above-mentioned states will not be permitted as per the state government prohibitions.
                          </p>
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="400">
                        <a
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-list-6"
                          class="collapsed"
                        >
                          Is the money earned from Fantasy sports taxable in India?
                          <i class="bx bx-chevron-down icon-show"></i>
                          <i class="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-6"
                          class="collapse"
                          data-bs-parent=".faq-list"
                        >
                          <p>
                          Yes. Under the provisions of Income Tax Act 1961, Tax deducted at source (TDS) is applied to the winning amount and you’ll receive the winning amount after the same has been deducted by the companies. If applicable you’ll also receive a legal TDS certificate from the company’s end for future references. 
                          </p>
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="400">
                        <a
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-list-8"
                          class="collapsed"
                        >
                          Can I play Fantasy sports from all mobile devices? 
                          <i class="bx bx-chevron-down icon-show"></i>
                          <i class="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-8"
                          class="collapse"
                          data-bs-parent=".faq-list"
                        >
                          <p>
                          Of course, you can. The beauty of online fantasy games is that you can play them anytime and at any place from any device – be it a smartphone, tablet, PC, or a laptop! All apps are designed to make them mobile user-friendly. The HTML versions of the apps are fully adapted for mobile phones. By making them compatible with all types of devices, we’re trying to make the gaming experience seamless, smooth, and enjoyable. 
                          </p>
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="400">
                        <a
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-list-9"
                          class="collapsed"
                        >
                          How do the referral bonuses work? 
                          <i class="bx bx-chevron-down icon-show"></i>
                          <i class="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-9"
                          class="collapse"
                          data-bs-parent=".faq-list"
                        >
                          <p>
                          You can earn cash in daily fantasy cricket with attractive referral bonuses as well. As soon as you download the apps, most fantasy sports sites will highlight the referral bonus part where you can refer new players and get rich with a flash of seconds! You get a percentage of commission for promoting the site. The bonus rates may vary from site to site.
                          </p>
                        </div>
                      </li> 



                      <li data-aos="fade-up" data-aos-delay="400">
                        <a
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-list-10"
                          class="collapsed"
                        >
                          What are the unique features of NLGFANTASY? 
                          <i class="bx bx-chevron-down icon-show"></i>
                          <i class="bx bx-chevron-up icon-close"></i>
                        </a>
                        <div
                          id="faq-list-10"
                          class="collapse"
                          data-bs-parent=".faq-list"
                        >
                          <p>
                          For cricket-crazy people, NLGFANTASY is the simplest, thrilling, and realistic fantasy sports platform which will give you the opportunity of winning huge amounts of cash and let you sharpen your gaming skills.
                          </p>
                          <p>
                          Let’s jot down a few pointers: 
                          </p>
                          <ul className="mt-3" > 
                            <li className="p-0" >All device compatible gaming application.</li> 
                            <li className="p-0" >Attractive registration fees and referral bonuses. </li>
                            <li className="p-0" >Nail-biting cricket and football leagues. </li>
                            <li className="p-0" >Pro tips on how to play fantasy games24/7 prompt customer support system. </li>
                          </ul>


                        </div>
                      </li> 


                    </ul>
                  </div>
          </div>
        </section>
        {/* <!-- End F.A.Q Section --> */}

        {/* <!-- ======= Contact Section ======= --> */}
  
      </main >
      <Footer />
      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
};
