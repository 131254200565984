import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const Policy = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <header id="header" class="d-flex align-items-center header-transparent ">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo">
            {/* <!-- <h1><a href="index.html"><span>Bootslander</span></a></h1> -->
        <!-- Uncomment below if you prefer to use an image logo --> */}
            <Link to="/">
              <img src="img/logo.svg" alt="" class="img-fluid" />
            </Link>
          </div>

          {/* <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://backend.www.nlgfantasy.com/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav> */}

          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec">
        <div class="heading">
          <h1>Privacy Policy</h1>
        </div>
      </section>

      <main id="main">
        <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Privacy Policy</li>
              </ol>
            </div>
          </div>
        </section>
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page">
          <div class="container">
            <div class="content"> 
              <p class="lh18 pdTop15">
              " www.nlgfantasy.com" is dedicated to respect the privacy of everybody who shares personal details with us through ‘www.nlgfantasy.com’.
              </p>
               
               
              <p class="lh18 pdTop15">
              In this privacy policy, we aim to provide you with better information about how your 'Personal Identifiable Information' (PII) is used online. Personal information could be used to categorize, contact, or locate an individual, or recognize him or her in context, on its own or in combination with other details. Please review our privacy policy carefully so that you have a clear understanding of how we collect, use, protect, or otherwise handle your Personally Identifiable Information
              </p>
               <br/>
              <p class="lh18 pdTop15">
                <strong>What personal information do we collect from those who visit our blog or website?</strong>
              </p>
              <p class="lh18 pdTop15">
              Registration on our site will require you to enter information such as your name, e-mail address, phone number, etc.
              </p>


              <br/>
              <p class="lh18 pdTop15">
                <strong>
                What is the timeline for getting the details?
                </strong>
              </p>
              <p class="lh18 pdTop15">
              Your details are collected when you register on our site, complete a form, respond to a survey, enter details on our site, use Live Chat, or provide feedback for our services.
              </p>


              <br/>
              <p class="lh18 pdTop15">
                <strong> 
                HOW DO WE USE YOUR INFORMATION?
                </strong>
              </p>
              <p class="lh18 pdTop15">
              Your information may be used in the following ways when you register, make a deposit or withdrawal, respond to a survey or marketing communication, browse the site, or use a few other site attributes:
              </p>
              <p>
              To personalize your experience and to provide you with the kind of content you enjoy most.
              </p>
              <p>
              To improve our website so that we can serve you better.
              </p>
              <p>
              To enable us to better serve your customer service requests.
              </p>
              <p>
              Administering a contest, promotion, survey, or other website feature.
              </p>
              <p>

              To complete your transactions quickly Inquiring about reviews and ratings of services
              </p>
              <p>
              To follow up on correspondence (live chat, email or phone enquiries)
              </p>

              <br/>
              <p class="lh18 pdTop15">
                <strong> 
                How do we protect your data?
                </strong>
              </p>
              <p class="lh18 pdTop15">
              Our website is regularly checked for security gaps and vulnerabilities are identified to make your stay on our website as safe as possible.
              </p>
              <p class="lh18 pdTop15">
              Your personal information resides on secure networks and is accessible only by a limited number of individuals who have special access rights to such systems and are required to keep the information confidential. Additionally, any sensitive information you provide is encrypted using Secure Socket Layer (SSL) technology.
              </p>
              <p class="lh18 pdTop15">
              We take a number of security measures when a user enters, submits or accesses their information to ensure the security of your personal information.
              </p>
              <p class="lh18 pdTop15">
              Every transaction is routed through the gateway provider and is not stored on our servers.
              </p>



              <br/>
              <p class="lh18 pdTop15">
                <strong> 
                Do we use “cookies”?
                </strong>
              </p>
              <p class="lh18 pdTop15">
              Yes. Cookies are small files that a website or its service provider transfers to your computer's hard drive through your browser (if you allow) that allow the website's or service provider's systems to recognize your browser, capture certain details. Allows to do and remember.
              </p>
              <p>
              They are also used to help us recognize your preferences based on past or present activity on the Website, which enables us to provide you with better services. We also use cookies to collect aggregate data about site traffic and site interactions so that we can offer better site experiences and tools in the future.
              </p>
              <p>
              <b>We use cookies to:</b>
              </p>
              <p>
              Learn about user preferences and save them for future visits. Collect aggregate data about site traffic and site interactions to provide better site experiences and tools in the future. We may also use trusted third-party services to track this information on our behalf. You can choose to have your PC warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since browsers vary slightly, check your browser's Help menu to learn how to properly customize your cookies.
              </p>
              <p>
              If you disable your cookies, some features that make your website experience more efficient may not function properly. It does not affect user understanding, making your website's information more efficient and does not work properly
              </p>

              <p>
              Third Party Disclosures We do not sell, trade, or otherwise transfer your PII to third parties.
              </p>
              <p>
              Third-Party Links We do not include or offer third-party services on our website.
              </p>
              <p>
              You will be notified of any changes to the Privacy Policy: on our Privacy Policy page
              </p>
              <p>
              You can change your personal information:
              </p>
              <p>
              By logging into your account
              </p>
              <p>
              By chatting with us or sending us a support ticket
              </p>
              <p>
              How does our website handle “Do Not Track” signals? We respect Do Not Track signals and do not track, place cookies or use advertising when Do Not Track (DNT) browser mechanisms are in place.
              </p>
              <p>
              Does our website allow third-party behavioral tracking? It is also important to note that we do not allow third party behavioral tracking
              </p>
              <p>
              Children's Statement Another focus of our work is protecting children when using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activities.
              </p>
              <p>
              www.nlgfantasy.com does not knowingly collect personal information from children under the age of 13. If you think that your child provided this type of information on our website, we encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
              </p>  

              <br/>
              <p class="lh18 pdTop15">
                <strong> 
                Contact
                </strong>
              </p>
              <p class="lh18 pdTop15">
              We can address any questions, comments and concerns you may have about our online privacy practices and policies. Please write to us at contact@nlgfantasy.com. This game may be habit-forming or financially risky. Play responsibly
              </p>
 
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      <Footer />
      {/* <!-- End Footer --> */}

      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>
      {/* <div id="preloader"></div> */}
    </>
  );
};
